import { media, selectTheme, styled, Typography } from '@creditas-ui/react'
import { Colors } from '../../../utils/colors';

const Wrapper = styled.section`
  width: 100vw;
  background-color: ${selectTheme('colors.neutral.90')};
`

const DesktopContainer = styled.div`
  ${media.down('4xl')} {
    display: none;
  }
`

const MobileContainer = styled.div`
  ${media.up('5xl')} {
    display: none;
  }
`

const LegalContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 48px 0;
  ${media.between('5xl', '8xl')} {
    margin: 0 71px;
  }
  ${media.down('4xl')} {
    padding: 32px 20px;
  }
`

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -16px;

  ${media.down('xl')} {
    flex-direction: column;
  }
`

const IconContainer = styled.a`
  display: flex;
  flex-direction: row;
  padding: 1em;
`

const InstitutionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.down('xl')} {
    margin-top: 34px;
  }
`

const TextContainer = styled.div`
  margin: 48px 0;
  border-bottom: 1px solid ${selectTheme('colors.neutral.80')};
  ${media.down('2xl')} {
    margin: 40px 0 48px;
    border-bottom: 0;
  }

  a,
  a:visited {
    color: ${selectTheme('colors.neutral.40')};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`

const Text = styled(Typography)`
  margin: 16px 0;

  :last-of-type {
    margin-bottom: 48px;
  }
`

const LegalSubcontainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${media.down('4xl')} {
    flex-direction: column;
  }
`
const LegalItems = styled.div`
  display: flex;
  flex-direction: row;
  ${media.down('2xl')} {
    flex-direction: column;
  }
`

const LegalLinks = styled(Typography)`
  ${media.down('2xl')} {
    padding-bottom: 24px;
  }
  ${media.up('3xl')} {
    :not(:last-child):after {
      content: '';
      background-color: ${Colors.Green};
      display: inline-block;
      width: 3px;
      height: 3px;
      border-radius: 6px;
      margin: 2px 18px 2px;
    }
  }
`
const Link = styled.a`
  color: ${selectTheme('colors.neutral.40')};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const RightsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  ${media.between('2xl', '5xl')} {
    margin-top: 32px;
  }

  ${media.down('2xl')} {
    border-top: 1px solid ${selectTheme('colors.neutral.80')};
    margin-top: 20px;
  }
`
const RightsText = styled(Typography)`
  margin-right: 3px;
  ${media.down('2xl')} {
    padding-top: 27px;
  }
`

export {
  Wrapper,
  DesktopContainer,
  MobileContainer,
  LegalContainer,
  IconContainer,
  InstitutionsContainer,
  SocialMediaContainer,
  TextContainer,
  Text,
  IconsContainer,
  LegalSubcontainer,
  LegalItems,
  LegalLinks,
  Link,
  RightsContainer,
  RightsText,
}
